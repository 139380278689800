/** 
 *   热销
 */
import { get } from '@/untils/js/axios.js'

// 浏览量
export const getRank = (id,params) => get(`/goods/columns-goods/${id}`, params)

// 获得热销分类
export const getRankClassify = params => get(`/goods/columns-type/3`, params)

// 获得分类下商品
export const getRankClassifyGoods = (id,params) => get(`/goods/columns-type-goods/${id}`, params)

